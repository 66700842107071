import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { Container, Row, Col, Card, Table, Navbar, Nav, NavDropdown, Button, Badge } from 'react-bootstrap';
import './index.css';
import { Link } from 'react-router-dom';
import { showError } from '../../utils/error-handler';
import { API_GET_USERS } from '../../backend/apis';
import api from '../../backend/axios-config';

const Dashboard: React.FC = () => {
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [totalRecipes, setTotalRecipes] = useState<number>(0);
    const [totalIngredients, setTotalIngredients] = useState<number>(0);
    const [usersList, setUsersList] = useState<any[]>([] as any);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchStats();
        fetchUsersList();
    }, []);

    const fetchStats = async () => {
        try {
            const sampleStats = {
                totalUsers: 0,
                totalRecipes: 0,
                totalIngredients: 0,
                newUsersToday: 0,
            };
            setTotalUsers(sampleStats.totalUsers);
            setTotalRecipes(sampleStats.totalRecipes);
            setTotalIngredients(sampleStats.totalIngredients);
        } catch (error) {
            console.error('Error fetching stats:', error);
        }
    };

    const fetchUsersList = async () => {
        try {
            setLoading(true);
            const response = await api.get(API_GET_USERS);
            setLoading(false);
            const users = response.data["data"];
            setUsersList(users);
        } catch (error: any) {
            console.error('Error fetching users list:', error);
            setLoading(false);
            showError(error.message);
        }

        // try {
        //     const sampleUsers = [
        //         { id: 1, name: 'John Doe', email: 'john@example.com', role: 'Admin', status: 'Active', phone: '123-456-7890' },
        //         { id: 2, name: 'Jane Smith', email: 'jane@example.com', role: 'User', status: 'Active', phone: '987-654-3210' },
        //         { id: 3, name: 'Alice Johnson', email: 'alice@example.com', role: 'User', status: 'Inactive', phone: '555-555-5555' },
        //         { id: 4, name: 'Alice Johnson', email: 'alice@example.com', role: 'User', status: 'Inactive', phone: '555-555-5555' },
        //         { id: 5, name: 'Alice Johnson', email: 'alice@example.com', role: 'User', status: 'Inactive', phone: '555-555-5555' },
        //         { id: 6, name: 'Alice Johnson', email: 'alice@example.com', role: 'User', status: 'Inactive', phone: '555-555-5555' },
        //         { id: 7, name: 'Alice Johnson', email: 'alice@example.com', role: 'User', status: 'Inactive', phone: '555-555-5555' },
        //     ];
        //     setUsersList(sampleUsers);
        // } catch (error) {
        //     console.error('Error fetching users list:', error);
        // }
    };

    return (
        <>

            <Container fluid className="mt-3">
                {/* <Row className="mt-3">
                    <Col md={4} sm={4}>
                        <Card className="shadow-sm" bg="light" border="success">
                            <Row>
                                <Col md={3}>
                                    <div className="image-left">
                                        <Card.Img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnGrnC7RMPo1gKBK-XVq1MOGDHItlBXc0FoQC7Xtio4A&s" className="image img-fluid" />
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <Card.Body className="text-center">
                                        <Card.Title>Total Users</Card.Title>
                                        <Card.Text className="display-4"><Link to="/users" className='text-decoration-none text-dark'>{totalUsers}</Link></Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col md={4} sm={4}>
                        <Card className="shadow-sm" bg="light" border="warning">
                            <Row>
                                <Col md={3}>
                                    <div className="image-left">
                                        <Card.Img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkyf0jzdzzv5tLub-Iriwy6UYZl6ug7bfK1JxzrzpdKg&s" className="image img-fluid" />
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <Card.Body className="text-center">
                                        <Card.Title>Total Recipes</Card.Title>
                                        <Card.Text className="display-4"><Link to="/recipes" className='text-decoration-none text-dark'>{totalRecipes}</Link></Card.Text>
                                    </Card.Body>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}

                <Row className="mt-3">
                    <Col>
                        <Card>
                            <Card.Header as="h5">Registered Users List</Card.Header>
                            <Card.Body>
                                {usersList.length > 0 ? (
                                    <Table striped bordered hover style={{ textAlign: "start" }}>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Email</th>
                                                {/* <th>Role</th> */}
                                                <th>Status</th>
                                                <th>Phone</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {usersList.map((user, index) => (
                                                <tr key={"ti-" + index}>
                                                    <td>{index + 1}</td>
                                                    <td>{user.first_name + " " + user.last_name}</td>
                                                    <td>{user.email}</td>
                                                    {/* <td>{user.role}</td> */}
                                                    <td><Badge>{user.status}</Badge></td>
                                                    <td>{user.phone}</td>
                                                    <td>
                                                        <Link className="btn btn-success" to={"/users/" + user.id}>View Details</Link>{' '}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                ) : (
                                    <p className="text-center">No users found</p>
                                )}
                                <Link to="/users">Check all users</Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Dashboard;
