import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import UserDetail from './screens/Users/UserDetail';
import { Container, Nav, Navbar } from 'react-bootstrap';
import Users from './screens/Users';
// import logo from './assets/images/logo.png';
import logo from './assets/images/icon.png';
import './App.css';
import Recipes from './screens/Recipes';
import RecipeDetail from './screens/Recipes/RecipeDetail';
import Login from './screens/auth/Login';
import ForgotPassword from './screens/auth/ForgotPassword';
import ResetPassword from './screens/auth/ResetPassword';
// import { get } from 'http';
import { getAccessToken, removeTokens } from './backend/tokenStorage';
import { ToastComponent } from './utils/error-handler';
import { removeUser } from './backend/sessionStorage';
import Homepage from './screens/auth/Homepage';
import PrivacyPolicy from './screens/auth/PrivacyPolicy';
import TermsAndConditions from './screens/auth/TermsAndContidionts';
import AboutUs from './screens/auth/AboutUs';
import api from './backend/axios-config';
import DeleteAccountRequest from './screens/auth/DeleteAccountRequest';
import DeleteAccountConfirm from './screens/auth/DeleteAccountConfirm';

// import './App.css';
function App() {
  const handleLogout = async () => {

    try {

      // Clear refresh token on the server side (API call)
      const response = await api.post("/admin/logout");

      await removeTokens();
      await removeUser();
      window.location.href = window.location.href;

    } catch (error) {
      console.error('Error during logout:', error);

      await removeTokens();
      await removeUser();
      window.location.href = window.location.href;

      // Alert.alert('Error', 'An error occurred. Please try again.');
    }



    window.location.href = window.location.href;
  }
  if (getAccessToken()) {
    console.log("USER IS LOGGED-IN");
    return (<BrowserRouter>
      <>
        <ToastComponent />
        <Navbar variant="dark" expand="lg" style={{ backgroundColor: "#e76220",color:"3333333" }}>
          <Container>
            <Navbar.Brand href="/">
              <img src={logo} style={{background:"#ffffff",padding:10,borderRadius:5, width: 75 }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link className='nav-link' to="/">Home</Link>
                <Link className='nav-link' to="/users">Manage Users</Link>
                <Link className='nav-link' to="/recipes">Manage Recipes</Link>
              </Nav>
              <button className="btn btn-outline-light" onClick={() => {
                handleLogout()
              }}>Logout</button>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userId" element={<UserDetail />} />
          <Route path="/recipes" element={<Recipes />} />
          <Route path="/recipes/:recipeId" element={<RecipeDetail />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </>
    </BrowserRouter>);
  }
  return (<BrowserRouter>
    <>
      <ToastComponent />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/delete-account" element={<DeleteAccountRequest />} />
        <Route path="/delete-account/:token" element={<DeleteAccountConfirm />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  </BrowserRouter>);
}

export default App;
