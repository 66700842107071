import { Container } from "react-bootstrap";
// import logo from '../../../assets/images/logo.png';
import logo from '../../../assets/images/icon.png';
export default function SectionHero() {
    return (<div className="jumbotron text-center text-white py-2 mb-0">
        <Container>
            <a href="https://nutreelover.com" className="text-decoration-none text-white">
                {/* <h1 className="display-4">NutreeLover.com</h1> */}
                {/* <img src={logo} alt="Logo" height="175px" /> */}
                <img src={logo} alt="Logo" style={{maxWidth:350}} />
            </a>
            <p className="lead">Personalized Meal Planning that is Easy, Inexpensive, and Nutritious</p>
            {/* <Button variant="outline-light" href="#intro">Learn More</Button> */}
        </Container>
    </div>)
}